.logo-container {
    display: flex;
    width: 100%;
    height: 65px;
    position: fixed;
    bottom: 0;
    left: 0%;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1%;
}

.logo-cnc {
    width: 135px;
    height: auto;
}

.logo-ccce,
.logo-faceit {
    height: 46px;
    width: auto;
    color: black;
}